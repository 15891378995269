@import "../../styles/variables";

// .modal-lg {
//     max-width: 1200px !important;
// }

.car-analysis-form {
    margin: 1rem;

    .react-datepicker-popper {
        width: 500px;
    }

    .input-group {
        min-width: 10rem;
    }

    #car-parametres-area {
        h3 svg {
            padding-top: 4px;
        }
    }
}
@import "../../styles/variables";
#collisions-panel {
  margin-bottom: 1rem;
  .icon--header {
    font-size: 2rem;
    margin-right: 20px;
    cursor: pointer;
  }
  .btn--toggle {
    position: absolute;
    right: 10px;
  }
}

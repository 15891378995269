.conformity-area {
  border-top: 1px solid grey;
  padding-top: 1rem;
  //override default behavior
  .u-text-right {
    text-align: left !important;
  }

  .form-label {
    margin-left: 15px !important;
  }

}



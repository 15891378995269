@import "../../styles/variables";


.tabs {
  box-shadow: 0 0.5rem 0.8rem -0.8rem gray;
  margin-bottom: 1rem;;
  margin-top: 1rem;;
  a.nav-item {
     margin: 0 0.2rem 0 0;
  }

  a.nav-item.active {
    font-weight: bold;
    color:$black;
    border: none;
    border-bottom: 4px solid $primary;
    background-color: $light;
  }
  a.nav-item:hover {
    border: none;
    border-bottom: 4px solid rgba($primary, 0.6);
    background-color: $light;
  }
}

.tab-content{
  margin-bottom: 2rem;
}

// Override default variables before the import

$blue: #007bff;
$indigo: #6610f2;
$purple: #800080;
$pink: #e83e8c;
$red: #dc3545;
$orange: #fd7e14;
$ochre: #e39d57;
$yellow: #ffc107;
$beige:#e5d3b3;
$limegreen: #bedf90 ;
$green: #4ba82e;
$darkgreen: #2e8b57;
$teal: #20c997;
$cyan: #65dede;
$white: #fff;
$gray: #a7aeb4;
$gray-dark: #353b41;
$primary: #4ba82e;
$secondary: #a7aeb4;
$success: #28a745;
$info: #0090d7;
$warning: #ffa200;
$danger: #e62336;
$light: #f8f9fa;
$dark: #343a40;
$black: #000000;

$colors: (
  "blue": $blue,
  "indigo": $indigo,
  "purple": $purple,
  "pink": $pink,
  "red": $red,
  "orange": $orange,
  "yellow": $yellow,
  "green": $green,
  "teal": $teal,
  "cyan": $cyan,
  "white": $white,
  "gray": $gray,
  "gray-dark": $gray-dark,
  "darkgreen": $darkgreen,
  "limegreen": $limegreen,
  "ochre": $ochre,
  "beige": $beige,
);

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "adnger": $danger,
);

$color-primary-typography: #4a4a4a;

$table-hover-bg: rgba($black, 0.075);

$yiq-text-dark: $dark;
$yiq-text-light: $white;
$yiq-contrasted-threshold:150;

$last-day-background-color:rgba($primary, 0.1);
$last-day-background-color-hover:rgba($primary, 0.25);

$emission-car-background-color:rgba($blue, 0.2);
$emission-car-background-color-hover:rgba($blue, 0.30);

$to-plan-background-color:rgba($warning, 0.1);
$to-plan-background-color-hover:rgba($warning, 0.25);

$analysis-background-color:#CC8137;
$analysis-background-color-hover:rgba($blue, 0.30);

$selected-background-color:#373ccc;
$selected-background-color-hover:rgba($blue, 0.30);

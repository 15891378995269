@import "../../styles/variables.scss";

.car-color-icon {
  border: 1px solid;
  cursor: default;
  &.text--dark {
    color: #4a4a4a;
  }
  &.text--light {
    color: #FFF;
  }
}

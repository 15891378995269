@import "../../../styles/variables";

.circle {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    background-color: $yellow;
  
    @each $color, $value in $colors {
      &--#{$color}{
        background-color: $value;
      }
    }   
  }

.condition_row {
    border-bottom: 2px black;
}


ul {
  list-style-type: none !important;
  margin: 0;
  padding: 0;
}

li{
  margin: 10px 0px 10px 0px;
}

.list-row {
  display: flex;
  flex-flow: row;
  cursor: pointer;
  list-style-type: none !important;
  position: relative;
  padding: 5px;
  border: 1px solid #f9fafa;
  background: #f9fafa;
}


.list-item{
  margin-left: 8px;
  margin-right: 5px;
}
@import "../../../styles/variables";

.vacation-header{
  padding-bottom: 2rem;
  padding-left: 1rem;
}

.calendars-container {
  display: flex;
  flex-wrap: wrap;
  
}

@import "../../../styles/variables.scss";

.schedule-emission-item {
  border-top : 1px solid;
  border-bottom: 1px solid;
  padding-left: 5px;
  padding-right: 5px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  cursor: pointer;
  font-size: smaller;
  box-shadow: 0 0.1rem 0.4rem -0.1rem rgba(0, 0, 0, 0.2);
  transition: all ease-in 0.1s;
  background-color: mix($gray-dark, white, 20%);
  border-color: mix($gray-dark, white, 80%);
  &:hover {
    background-color: mix($gray-dark, white, 40%);
  }

  &:hover {
    box-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.4);
    transform: translateY(-2px);
    z-index: 10;
  }
}

.first{
  border-top-left-radius: 0.3em;
  border-bottom-left-radius: 0.3em;
  border-left: 4px solid;
  padding-left: 5px;
}

.last{
  border-top-right-radius: 0.3em;
  border-bottom-right-radius: 0.9em;
  border-right: 1px solid;
  padding-right: 5px;
}


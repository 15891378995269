.react-datepicker-popper{
  z-index: 2;
}

.react-datepicker-time__header {
  visibility: hidden;
  position: relative;
}

.react-datepicker-time__header::after {
  content: "Čas";
  visibility: visible;
  position: absolute;
  top: 0;
  left: 20px;
}

.datepicker-invalid-feedback {
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #e62336;
}


@import "../../styles/variables";

#plan-page {
  .right {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  td {
    vertical-align: middle;
  }
  .carfilter {
    margin-top: 0;
    padding-top: 0;
  }
  #planfrom-field {
    .u-text-right {
      width: 5rem;
    }
  }
  tr.transported-car{
    color: $secondary;
  }

  .form-group.checkbox-group {
     margin-bottom: 0rem;
      .checkbox {
        vertical-align: middle;
        padding-top: 0rem;
      }
    }
}

@import "../../styles/variables";

.vin {
  &--important{
    color:$danger;
  }
  &__vinpart {
    font-weight: 300;
    font-size: smaller;
  }
  &__carnumber {
    font-weight: 600;
  }
}

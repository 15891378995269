@import "../../../../styles/variables.scss";
$disabled-bg-color: lightgray;

#WaterResistanceProtocol {
  .pageheader {
    position: sticky;
    top: 0;
    padding-top: 10px;
    background-color: white;
    z-index: 2;
    border-bottom: 1px solid grey;
  }
  table {
    table-layout: fixed;
  }
  .table-page-content {
    width: 100%;
  }

  .protocol-table {
    border-bottom: 1px solid $secondary;
    h2 {
      border-bottom: none;
      padding-bottom: 0;
    }
    .headingrow {
      border-bottom: 2px solid $primary;
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0;
    }
    .labelrow {
      padding-top: 10px;
      vertical-align: top;
    }
    table {
      margin-bottom: 0;
      padding-bottom: 5px;
    }
    td,
    th {
      border-top: 0;
      border-right: 0;
      border-bottom: 1px solid $disabled-bg-color;
      border-left: 0;
      padding: 0.5rem;
    }
    td {
      padding: 2px 5px;
      .form-group {
        margin-bottom: 4px;
        margin-top: 4px;
      }
      vertical-align: middle;
    }
  }

  .disabled {
    background-color: $disabled-bg-color;
  }

  .printheader {
    display: none;
  }
  .printfooter {
    display: none;
  }
  .text {
    &--header {
      text-transform: uppercase;
      font-size: larger;
    }
    &--text {
      text-transform: none;
    }
    &--expert-group {
      text-transform: uppercase;
    }
  }

  .carinfo.row {
    margin-left: 0;
    margin-right: 0;
  }

  .field-print-only {
    display: none !important;
  }

  .inline-field-1 {
    display: inline-block !important;
    width: 100%;
  }

  .inline-field-2 {
    display: inline-block !important;
    width: 50%;
  }

  .inline-field-3 {
    display: inline-block !important;
    width: 33%;
  }

  .text-print-only {
    opacity: 0%;
  }

  .ProtocolAddInfo .form-group {
    margin-bottom: 4px;
  }
  .ProtocolAddInfo label{
    font-size: 0.9rem;
    font-weight: normal;
    text-align: left;
  }
  .ProtocolAddInfo {
    margin-bottom: 0;
    margin-top: 0;
  }
  .ProtocolAddInfo input {
    border-top: 0;
    border-right: 0;
    border-bottom: 1;
    border-left: 0;
  }

/*   .flash-break {
    break-before: always
  }  */

  @media print {

    .field-print-only {
      display: block !important;
    }

    .text-print-only {
      opacity: 100%;
    }

    .page-header-space {
      height: 100px;
      padding-bottom: 40px;
      // border-bottom: 5px solid black;
    }
    .page-footer-space {
      height: 50px;
      margin-top: 20px
    }
    thead {
      display: table-header-group;
    }
    tfoot {
      display: table-footer-group;
    }
    button {
      display: none;
    }
    body {
      margin: 0;
    }
    .printheader {
      font-size: 0.8em;
      width: 92%;
      position: fixed;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      &__right {
        text-align: right;
      }
      &--image {
        display: flex;
        height: 80px;
        width: 262px;
      }
      img {
        max-width: 100%;
      }
      top: 0;
    }
    .printfooter {
      font-size: 0.8em;
      position: fixed;
      bottom: 0;
      width: 100%;
      border-top: 1px solid $secondary; /* for demo */
      display: flex;
      height: 55px;
    }

    .printfooter td {
      padding: 4px;
    }

    .carinfo.row {
      border-bottom: 1px solid $dark;
    }
    .protocol-table {
      .protocol-rule--disabled {
        display: none;
      }
      tbody {
        td {
          border: 1px solid $secondary;
          padding: 0.5rem;
          margin: 0;
          padding: 0;
          padding-left: 5px;
          &:last-child {
            width: 5em;
          }
        }
      }
      input,
      select,
      textarea {
        border: none;
        &.form-control:disabled {
          background-color: white;
        }
      }
      .batery-value-row {
        td {
          margin: 0;
          padding: 0;
        }
      }
      textarea {
        height: auto !important;
        max-height: auto !important;
        overflow: visible !important;
        resize: none;
      }

      select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
      }
    }

    .ProtocolAddInfo .form-group {
      margin-bottom: 0;
    }
    .ProtocolAddInfo label{
      font-size: 0.9rem;
      font-weight: normal;
      text-align: left;
    }
  }
}

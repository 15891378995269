@import "../../styles/variables.scss";

.navbar {
  border-top: 5px solid $primary;
  border-bottom: 3px solid rgba($primary, 0.1);
  .bg-light {
    background-color: $white;
  }
}

.nav-dropdown2{
  .dropdown-menu{
    right:0;
    left:unset;
  }
}

.navbar-brand-logo {
  padding-bottom: 6px;
}
.nav-user-info {
  &.dropdown-item.disabled {
    padding-left: 1rem;
  }
  .nav-user-info-icon {
    width: 0.6rem;
    margin-right: 5px;
    color: $primary;
  }
  ul {
    padding-left: 0rem;
    margin-bottom: 0;
    list-style: none;
    font-size: 0.9rem;
    li:first-child{
      font-weight: bold;
    }
  }
}

@import "../../styles/variables.scss";
@import "~bootstrap/scss/functions.scss";

.worker-badge {
  cursor: default;
  margin-left: 2px;
  font-size: 0.75rem;
  @each $color, $value in $colors {
    &--#{$color} {
      background-color: rgba($value, 1);
      color: color-yiq($value);
    }
  }
}

.worker-label {
  margin-right: 4px;
  font-weight: bold;
  @each $color, $value in $colors {
    &--#{$color} {
      color: $value;
    }
  }
}

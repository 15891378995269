@import "../../../styles/variables";

.filter-container {
  flex-basis: 100%;
  &__buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
 &__buttons{
    .btn {
        margin: 0.25rem;
        min-width: 2.5rem;
      }
 }

  &__filters {
      width: 100%;
    border-radius: 5px;
    box-shadow: 0 0.1rem 0.5rem rgba($black, 0.1);
    padding: 1rem;
    background-color: $light;
    margin-top:1rem;
    .select__menu {
      z-index: 2;
    }
    &__button {
      width: 10rem;
      margin-right: 1rem;     
    }
  }
}

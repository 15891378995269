@import "../../../styles/variables";

.month-calendar {
  flex: 1;
  padding: 1rem;
  margin-bottom: 2rem;
  border-radius: 5px;
  background-color: $white;
  box-shadow: 0 0.2rem 0.4rem rgba($black, 0.2);
  height: 22rem;
  table {
    width: 100%;
    th,
    td {
      text-transform: capitalize;
      text-align: center;
      width: calc(100% / 7);
      padding-top: 8px;
      padding-bottom: 8px;
    }

    td.calendar-day {
      transition: background-color 0.2s ease-in-out;
      &:not(.empty) {
        cursor: pointer;
        &:hover {
          background-color: rgba($secondary, 0.3);
          font-weight: bold;
        }
      }
      &--holiday {
        transition: background-color 0.5s ease-in-out;
        color: $primary;
        font-weight: 700;
        background-color: rgba($primary, 0.2);
      }
      &--vacation {
        transition: background-color 0.5s ease-in-out;
        color: $info;
        font-weight: 700;
        background-color: rgba($info, 0.2);
      }
    }
    td:nth-child(n + 6):not(.empty) {
      font-weight: bold;
      color: $success;
    }
    th {
      padding-top: 8px;
    }

    tr.table-row__month {
      text-align: center;
    }

    tr.table-row__weekdays {
      border-bottom: 2px solid $primary;
    }
  }
}

div.calendarday__popover {
  font-size: 1rem;
  ul {
    padding: 0.5rem 1.5rem;
    list-style-type: square;
    li::first-letter {
      text-transform: capitalize;
    }
    li:first-child {
      list-style-type: none;
      font-weight: 700;
    }
  }
  &--vacation{
    padding-right:3rem;
  }
}

@import "../../styles/variables";

#fullcalendar-page {
  .fc-day-grid-event.fc-event {
    border: none;
    //border-left: 2px solid;
    padding: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: smaller;
    box-shadow: 0 0.1rem 0.4rem rgba(0, 0, 0, 0.2);
    background-color: #fafafa;
    border-color: $secondary;
    min-width: 2rem;
    min-height: 1rem;
    // transition: min-height 0.5s , min-width 0.2s !important;//, scale 2s ease-in-out;
    // transition: all 0.2s;//, scale 2s ease-in-out;
    transition: all 0.4s ease-in-out;

    .fullcalendar-blockingevent-section {
      padding: 1px 2px;
    }

    .fullcalendar-blockingevent {
      border: 1px solid;
      border-left: 4px solid;
      display: flex;
      flex-direction: column;
      padding: 0px;
      //background-color: rgba($warning, 0.2);
      height: 100%;
      width: calc(100% - 4px);
      font-size: small;

      &--absence {
        color: $info;
        border-color: $info;

        //  background-color: rgba($info, 0.2);
        &:hover {
          background-color: rgba($info, 0.1);
        }
      }

      &--vacation {
        color: $primary;
        border-color: $primary;

        //  background-color: rgba($info, 0.2);
        &:hover {
          background-color: rgba($primary, 0.1);
        }
      }

      &__row {
        display: flex;
        //justify-content: space-between;
        flex-direction: row;
      }

      &__type {
        order: 1;
        font-weight: 600;
      }

      &__time {
        order: 2;
        color: lighten($color: $black, $amount: 50);
      }

      &__users {
        order: 3;
        padding: 0;
        font-weight: 600;
      }

      &__description {
        opacity: 0;
        padding-left: 4px;
        text-overflow: ellipsis;
        max-height: 0rem;
        transition: all 0.4s ease-in;
        color: lighten($color: $black, $amount: 40);
      }

      &__link {
        margin-left: auto;
        order: 4;

        &--btn {
          font-size: 0.9rem;
          color: $primary;
          padding: 1px;

          &:hover {
            color: darken($color: $primary, $amount: 10);
          }
        }
      }

      &:hover {
        .fullcalendar-blockingevent__description {
          opacity: 1;
          display: inline-block;
          max-height: 2rem;
        }
      }
    }
  }

  .fc-time-grid-event.fc-event.fullcalendar-blockingevent--blokace {
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: smaller;
    box-shadow: 0 0.1rem 0.4rem rgba(0, 0, 0, 0.2);
    transition: all 0.4s ease-in-out;

    .fullcalendar-blockingevent-section {
      padding: 1px 4px;
    }

    border: 1px solid;
    border-left: 4px solid;
    display: flex;
    flex-direction: column;
    padding: 2px;
    font-size: small;
    border-color: $warning;

    &:hover {
      background-color: mix($warning, $white, 10%);
      min-width: 7rem;
      min-height: 10rem;

      .fullcalendar-blockingevent__description {
        overflow-wrap: anywhere;
      }
    }

    .fullcalendar-blockingevent {
      &__type {
        color: $warning;

        font-weight: 600;
      }

      &__time {
        font-weight: bold;
      }

      &__users {
        font-weight: 600;
      }

      &__description {
        color: lighten($color: $black, $amount: 40);
      }

      &__link {
        &--btn {
          color: $primary;
          padding: 1px;

          &:hover {
            color: darken($color: $primary, $amount: 10);
          }
        }
      }
    }
  }

  .fc-time-grid-event.fc-event.fullcalendar-blockingevent--udalost {
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: smaller;
    box-shadow: 0 0.1rem 0.4rem rgba(0, 0, 0, 0.2);
    transition: all 0.4s ease-in-out;

    .fullcalendar-blockingevent-section {
      padding: 1px 4px;
    }

    border: 1px solid;
    border-left: 4px solid;
    display: flex;
    flex-direction: column;
    padding: 2px;
    font-size: small;
    border-color: #0000be;

    &:hover {
      background-color: mix(#0000be, $white, 10%);
      min-width: 7rem;
      min-height: 10rem;

      .fullcalendar-blockingevent__description {
        overflow-wrap: anywhere;
      }
    }

    .fullcalendar-blockingevent {
      &__type {
        color: #0000be;

        font-weight: 600;
      }

      &__time {
        font-weight: bold;
      }

      &__users {
        font-weight: 600;
      }

      &__description {
        color: lighten($color: $black, $amount: 40);
      }

      &__link {
        &--btn {
          color: $primary;
          padding: 1px;

          &:hover {
            color: darken($color: $primary, $amount: 10);
          }
        }
      }
    }
  }
}
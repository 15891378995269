.downshift-car-color {
  .input-wrapper {
    //  display: inline-block;
    //  position: relative;

    .car-color-item {
      float: right;
      //  margin-right: 6px;
      //  margin-top: -20px;
      position: absolute;
      z-index: 2;
      border-radius: 15px;
      margin-left: 8px;
      margin-top: 10px;
      height: 15px;
      width: 15px;
      border: 1px solid;
    }
    input {
      padding-left: 25px;
    }
  }
  .downshift-dropdown {
    max-height: 10rem;
    overflow-y: auto;
    width: 92% !important;
  }
  .dropdown-item {
    display: flex;
    flex-direction: row;
    div.car-color {
      border-radius: 15px;
      margin-right: 8px;
      margin-top: 2px;
      height: 15px;
      width: 15px;
      border: 1px solid grey;
    }
  }
}

@import "../../../styles/variables";


#carAnalysis-list{
    .last-term-border{
        border-bottom: 2px solid darkgrey;
    }

    .term-border{
        border-bottom: 1px solid darkgrey;
    }
}
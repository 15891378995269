@import "../../../styles/variables.scss";

.plandashboard-header {
  // position: relative;
  display: flex;
  place-items: row;
  //justify-content: space-around;
  margin: 10px 2rem 0;
  flex-wrap: wrap;
  .plandashboard-icon-left {
    margin-right: 35px;
  }

  .plandashboard-icon-right {
    margin-left: 35px;
  }

  .plandashboard-calendar-selector {
    color: $primary;
    font-weight: bold;
    font-size: larger;
  }
  .plandashboard-calendar{
     margin-top:4px;
      flex-grow: 1;
  }
  .plandashboard-header-name{
      flex-grow: 6;
  }
}
.break {
  flex-basis: 100%;
  height: 0;
}


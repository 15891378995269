@import "../../styles/variables";
#fullcalendar-page {
  .fc-time-grid .fc-event-container {
    &:hover {
      z-index: 10;
    }
  }
  .fc-time-grid-event.fc-event {
    border: 1px solid;
    border-left: 2px solid;
    padding: 2px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: smaller;
    margin-right: 1px;

    box-shadow: 0 0.1rem 0.4rem rgba(0, 0, 0, 0.2);
    background-color: #f5f5f5;
    border-color: $secondary;
    min-width: 2rem;
    min-height: 1rem;
    // transition: min-height 0.5s , min-width 0.2s !important;//, scale 2s ease-in-out;
    // transition: all 0.2s;//, scale 2s ease-in-out;
    transition: all 0.4s ease-in-out;
    &:hover {
      z-index: 10 !important;
      min-width: 6rem;
      min-height: 10rem;
      background-color: #e0ecf9;
    }
    &.fullcalendar-event {
      &--conflict {
        border: 2px $danger groove;
      }
      &--late {
        background: repeating-linear-gradient(
            135deg,
            mix($secondary, $white, 15%),
            mix($secondary, $white, 15%) 15px,
            mix($secondary, $white, 45%) 15px,
            mix($secondary, $white, 45%) 30px
          );
        &.fullcalendar-event--ongoing {
          background: repeating-linear-gradient(
            135deg,
            mix($info, $white, 15%),
            mix($info, $white, 15%) 15px,
            mix($info, $white, 30%) 15px,
            mix($info, $white, 30%) 30px
          );
        }      
        &.fullcalendar-event--completed {
          background: repeating-linear-gradient(
            135deg,
            mix($primary, $white, 15%),
            mix($primary, $white, 15%) 15px,
            mix($primary, $white, 30%) 15px,
            mix($primary, $white, 30%) 30px
          );
        }      
      }

      &--missing-worker {
        border: 2px $danger groove;
        // border-left: 4px solid $danger;
        background:$white;
      }

      // &--planned {
      // }

      &--ongoing {
        background-color: mix($info, $white, 25%);
        &:hover {
          background-color: mix($info, $white, 35%);
        }       
      }

      &--completed {
        background-color: mix($primary, $white, 25%);
        &:hover {
          background-color: mix($primary, $white, 35%);
        }
      }
    }
    .fullcalendar-event {
      display: flex;
      flex-direction: column;

      background-color: rgba(#f5f5f5, 0.4);
      height: 100%;
      width: 100%;

      &__time {
        font-weight: 600;
      }

      &__car {
        cursor: help;
        margin-left: 3px;
        .car-important{
          color:$danger;
font-weight: bolder;
        }
      }

      &__alerts {
        color: $danger;
        display: flex;
        flex-direction: row;
        div.tooltip-icon {
          padding: 2px;
          transition: all 0.2s ease-in-out;
          &:hover {
            transform: scale(1.15);
            color: darken($color: $danger, $amount: 15) !important;
          }
        }
      }
      &__link {
        &--btn {
          color: $primary;
          padding: 1px;
          &:hover {
            color: darken($color: $primary, $amount: 10);
          }
        }
      }
    }
  }
}

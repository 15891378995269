@import "../../styles/variables";

.loader {
  width: 100%;
  height: 90%;
  padding-top: 10%;
  &__label {
    text-align: center;
  }

  &__spinner,
  &__spinner:after {
    border-radius: 50%;
    width: 5rem;
    height: 5rem;
  }
  &__spinner {
    margin: 0rem auto 0rem auto;
    position: relative;
    // text-indent: -9999em;
    border-top: 0.5rem solid rgba($primary, 0.1);
    border-right: 0.5rem solid rgba($primary, 0.1);
    border-bottom: 0.5rem solid rgba($primary, 0.1);
    border-left: 0.5rem solid $primary;
    transform: translateZ(0);
    //animation: fadeOut ease 1s;
    animation: spinner 1.1s infinite linear;
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes fadeOut {
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
  }
}

.lvrow {
  padding: 0.6rem 0 0.6rem 0;
  &__label {
    font-weight: lighter;
    text-align: right;
    &::after {
      content: ":";
    }
  }
}

@import "../../styles/variables";

#exceeded-limit-icon {
  .icon-exceeded-limit {
    &--warning {
      color: $warning;
    }
    &--danger {
      color: $danger;
    }
  }
}

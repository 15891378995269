@import "./variables";
@import "./typography";
@import "./mixins";

// Typography
//
// Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif: "SKODA Next", -apple-system, BlinkMacSystemFont,
  "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
  "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$font-family-monospace: Monaco, SFMono-Regular, Menlo, Consolas,
  "Liberation Mono", "Courier New", monospace !default;
$font-family-base: $font-family-sans-serif !default;

$body-color: $color-primary-typography;

$font-size-base: 1rem;
$h1-font-size: 1.8rem;
$h2-font-size: 1.5rem;
$h3-font-size: 1.3rem;
$h4-font-size: 1.1rem;
$h5-font-size: 1.15rem;
$h6-font-size: 1rem;

// Links
//
// Style anchor elements.

$link-color: $gray-dark;
$link-decoration: none;
$link-hover-color: darken($gray-dark, 25%);
$link-hover-decoration: none;

html,
body,
#root {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
}

#root {
  display: flex;
  flex-direction: column;
}
.page-content {
  flex: auto;
  overflow: auto;
}
.print-only {
  display: none;
}

@media print {
  .page-content {
    flex: auto;
    overflow: visible;
  }
  footer,
  .no-print,
  .no-print * {
    display: none !important;
  }
  .print-only {
    display: block;
  }
}

// NAVBAR
//
// navs
$navbar-light-color: rgba($black, 0.8);
$navbar-light-active-color: $primary;
$navbar-brand-padding-y: 0;

// Buttons

// Allows for customizing button radius independently from global border radius
$btn-border-radius: 2rem;
$btn-border-radius-lg: 2.5rem;
$btn-border-radius-sm: 1.5rem;

.btn-group .btn:last-child {
  border-radius: 2rem;
}

.btn-group .btn:first-child {
  border-radius: 2rem;
}
// Tooltips

$tooltip-opacity: 0.8;

$tooltip-arrow-width: 0.8rem;
$tooltip-arrow-height: 0.4rem;
$tooltip-arrow-color: rgba($black, 0);

// Popovers
$popover-max-width: 80vw;
$popover-box-shadow: 0 0.3rem 0.8rem rgba(0, 0, 0, 0.5);

//Tables

tr.selected {
  background-color: rgba($secondary, 0.2);
  &:hover {
    background-color: rgba($secondary, 0.3) !important;
  }
}

.u-text-right {
  text-align: right;
}

.form-label {
  padding-top: 0.375rem;
  font-weight: 500;
}
h2 {
  border-bottom: 2px solid $primary;
  padding-bottom: 0.3rem;
}
button:disabled {
  cursor: not-allowed;
}

.container-full {
  margin: 0 3rem;
}

.btn .show-on-hover {
  display: none;
}

.btn:hover {
  .show-on-hover {
    display: inline-block;
  }
}
.column-align-right {
  display: flex;
  flex-direction: column;
  align-content: flex-end;
}
.pageheader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.tab-content {
  margin-top: 1rem;
}

// Form validation

$form-feedback-valid-color: $success;
$form-feedback-invalid-color: $danger;
$form-feedback-warning-color: $warning;

$form-feedback-icon-valid-color: $form-feedback-valid-color;
$form-feedback-icon-valid: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'><path fill='#{$form-feedback-icon-valid-color}' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/></svg>");
$form-feedback-icon-warning-color: $form-feedback-warning-color;
$form-feedback-icon-warning: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='#{$form-feedback-icon-warning-color}' viewBox='0 0 12 12'><circle cx='6' cy='6' r='4.5'/><path stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/><circle cx='6' cy='8.2' r='.6' fill='#{$form-feedback-icon-warning-color}' stroke='none'/></svg>");
$form-feedback-icon-invalid-color: $form-feedback-invalid-color;
$form-feedback-icon-invalid: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='#{$form-feedback-icon-invalid-color}' viewBox='0 0 12 12'><circle cx='6' cy='6' r='4.5'/><path stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/><circle cx='6' cy='8.2' r='.6' fill='#{$form-feedback-icon-invalid-color}' stroke='none'/></svg>");

$form-validation-states: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$form-validation-states: map-merge(
  (
    "valid": (
      "color": $form-feedback-valid-color,
      "icon": $form-feedback-icon-valid,
    ),
    "invalid": (
      "color": $form-feedback-invalid-color,
      "icon": $form-feedback-icon-invalid,
    ),
    "warning": (
      "color": $form-feedback-warning-color,
      "icon": $form-feedback-icon-warning,
    ),
  ),
  $form-validation-states
);

select.form-control.is-warning,
select.form-control.is-invalid {
  background-position: right calc(0.75em + 0.1875rem) center !important;
}

.form-label.required::after {
  content: "*";
  padding-left: 2px;
  color: $danger;
}

.form-label {
  margin-bottom: 0;
}

.icon {
  margin: 3px;
  &--primary {
    color: $primary;
    &:hover {
      color: darken($color: $primary, $amount: 10);
    }
  }
}

table.sticky--top th {
  position: sticky;
  background-color: $white;
  top: -1px;
  z-index: 1;
  box-shadow: inset 0 1px 0 rgba($black, 0.1), inset 0 -2px 0 rgba($black, 0.1);
}

.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-space-between {
  justify-content: space-between;
}

.flex-space-around {
  justify-content: space-around;
}

.flex-center {
  justify-content: center;
}

.flex-end {
  justify-content: flex-end;
}

div.align-right {
  margin-left: auto;
}

.btn-modal {
  margin: 0.5rem;
  min-width: 8rem;
}

.animate-down {
  &:hover {
    animation: go-down 0.5s ease-in-out;
  }
}

@keyframes go-down {
  50% {
    transform: translateY(2px);
  }
}

.animate-up {
  &:hover {
    animation: go-up 0.5s ease-in-out;
  }
}

@keyframes go-up {
  50% {
    transform: translateY(-2px);
  }
}

button.btn-link {
  border: none;
  background-color: initial;
  //font-weight: 300;
  &:hover {
    color: $black;
  }
  &:focus {
    outline: none;
  }
  &--primary {
    color: rgba($black, 0.8);
    &:hover {
      color: rgba($black, 1);
    }
  }
}
.tooltip.tooltip-color {
  .tooltip-inner{
    max-width: 25rem;

  }
}

.text-font-light{
  font-weight: 100;
}

.text-small{
  font-size: smaller;
}

.col-4{
  padding: 0 5px !important;
}


// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";

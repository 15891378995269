@import "../../styles/variables";

.place-badge {
  color: $info;
  font-size: 0.7rem;
  font-weight: bold;
  display: inline;
  padding: 2px 4px;
  border-radius: 5px;
  border: 2px solid $info;
  border-top: 1px solid $info;
}

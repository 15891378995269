@import "../../styles/variables.scss";

.carstatusicon {
  margin-right: 4px;
  //size: 0.7rem;
  &--danger {
    color: $danger;
  }
  &--success {
    color: $success;
  }
  &--warning {
    color: $warning;
  }
}

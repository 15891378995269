@import "../../../styles/variables";

.btn-group {
  margin-bottom: 1rem;
  .btn {
    min-width: 5rem;
    &:last-child {
      border-radius: 0 4px 4px 0;
    }
    &:first-child {
      border-radius: 4px 0 0 4px;
    }
  }
  .btn-outline-primary {
    &:hover:not(:disabled){
      background-color: darken($primary, $amount: 5);

    }
  }
}
.print-value {
  padding-top: 0.375rem;
  display: none;
}
@media print {
  .print-value {
    display: block;
  }
}

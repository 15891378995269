@import "../../styles/variables";

#emissionCars {
  .btn-group .btn {
    min-width: 3rem;
}
  .date-col-sm {
    width: 6rem;
  }
  .align__right{
    min-width: 33%;
    text-align: right;
  }
  .pageheader{
    margin-bottom: 0;
    display: flex;
    justify-content: space-between;  
  }
  tr:hover {
    background-color: rgba($color: $primary, $alpha: 0.1);
  }
  input:disabled {
    cursor: not-allowed;
  }
}

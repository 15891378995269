@import "../../../styles/variables.scss";
$disabled-bg-color: lightgray;

#CarBodyProtocol {
  .pageheader {
    position: sticky;
    top:0px;
    padding-top: 10px;
    background-color: white;
    z-index: 2;
    border-bottom: 1px solid grey;
  }
  table {
    table-layout: fixed;
  }
  .table-page-content{
    width:100%;
  }

  .protocol-area {
    border-top: 4px solid $primary;
    &--disabled {
      background-color: $disabled-bg-color;
    }
    h2 {
      border-bottom: none;
    }
  }
  .checkbox-group label {
    text-align: left;
    width: 100px;
  }
  .checkbox-group .checkbox {
    width: 10px;
  }
  .protocol-group {
    &:not(:last-child) {
      border-top: 3px solid $secondary;
    }
    &--disabled {
      background-color: $disabled-bg-color;
    }
  }

  .protocol-rule {
    & > td {
      border: none;
    }
    &--disabled {
      background-color: $disabled-bg-color;
    }
    &--header {
      padding-bottom: 0;
      margin-bottom: 0;
      h4 {
        margin-bottom: -5px;
      }
      small {
        font-size: 0.8em;
      }
    }
  }

  .ProtocolAddInfo .form-group {
    margin-bottom: 4px;
  }
  .ProtocolAddInfo label{
    font-size: 0.9rem;
    font-weight: normal;
    text-align: left;
  }
  .ProtocolAddInfo {
    margin-bottom: 0;
    margin-top: 0;
  }
  .ProtocolAddInfo input {
    border-top: 0;
    border-right: 0;
    border-bottom: 1;
    border-left: 0;
  }

  .protocol-rule .form-group {
    margin-bottom: 0;
  }
  .protocol-rule .col {
    padding: 0em;
  }
  .printheader {
    display: none;
    &--image {
      display: flex;
      margin: 10px;
      height: 80px;
      width: 262px;
    }
    img {
      max-width: 100%;
    }
  }
  .printfooter {
    display: none;
  }
  .text {
    &--header {
      text-transform: uppercase;
      font-size: larger;
    }
    &--text {
      text-transform: none;
    }
    &--expert-group {
      text-transform: uppercase;
    }
  }

  .carinfo.row {
    margin-left: 0;
    margin-right: 0;
  }

  .field-print-only {
    display: none !important;
  }

  .inline-field-1{
    display: inline-block !important;
    width: 100%;
  }

  .inline-field-2{
    display: inline-block !important;
    width: 50%;
  }

  .inline-field-3{
    display: inline-block !important;
    width: 33%;
  }

  .inline-field-4{
    display: inline-block !important;
    width: 25%;
  }

  .protocol-table {
    table {
      margin-bottom: 0;
      padding-bottom: 5px;
      border: none !important;
    }
    th,
    td{
      border-top: 0;
      border-right: 0;
      border-bottom: 1px solid $disabled-bg-color;
      border-left: 0;
      padding: 2px;
    }
    td {
      padding: 2px 5px;
      .form-group {
        margin-bottom: 4px;
        margin-top: 4px;
      }
      vertical-align: middle;
    }
  }


  @media print {

    .field-print-only {
      display: block !important;
    }

    .page-header-space {
      height: 100px;
    }
    .page-footer-space {
      height: 70px;
/*       border-top: 1px solid green;
      border-left: 1px solid red;
      border-right: 1px solid red;
      border-bottom: 1px solid red; */
    }
    thead {
      display: table-header-group;
    }
    tfoot {
      display: table-footer-group;
    }
    button {
      display: none;
    }
    body {
      margin: 0;
    }
    .printheader {
      font-size: 0.8em;
      width: 94.5%;
      position: fixed;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      &__right {
        text-align: right;
      }
    }
    .printfooter {
      font-size: 0.8em;
      position: fixed;
      bottom: 0;
      width: 100%;
      border-top: 2px solid $secondary; /* for demo */
      display: flex;
      height: 55px;
    }
    .printfooter td {
      padding: 4px;
    }

    .carinfo.row {
      border-bottom: 1px solid $dark;
    }
    #protocol-table {
      border: 1px solid $secondary;
      .protocol-rule--disabled {
        display: none;
      }
      td {
        margin: 0;
        padding: 0;
        padding-left: 5px;
        &:first-child {
          border: none;
        }
        &:last-child {
          // width: 5em;
        }
      }
      thead {
        & > tr {
          border-bottom: 2px solid $secondary;
        }
        td {
          border-left: 1px solid $secondary;
        }
      }
      tbody {
        & > tr {
          & > td {
            border-bottom: 1px dotted $secondary;
            border-left: 1px solid $secondary;
          }
        }
      }
      input, select {
        border: none;
        &.form-control:disabled {
          background-color: white;
        }
      }

      select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
      }
    }

    .protocol-table{
      table {
        margin-bottom: 0;
        padding-bottom: 5px;
      }
      th,
      td
      {
        border-top: 0;
        border-right: 0;
        border-bottom: 1px solid $disabled-bg-color;
        border-left: 0;
        padding: 0;
      }
      td {
        padding: 0px 2px;
        .form-group {
          margin-bottom: 0;
          margin-top: 0;
        }
        vertical-align: middle;
      }

      input,
      select,
      textarea {
        border: none;
        &.form-control:disabled {
          background-color: white;
        }
      }

      textarea {
        height: auto !important;
        max-height: auto !important;
        overflow: visible !important;
        resize: none;
      }

      select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
      }
    }

    .ProtocolAddInfo .form-group {
      margin-bottom: 0;
    }
    .ProtocolAddInfo label{
      font-size: 0.9rem;
      font-weight: normal;
      text-align: left;
    }
  }
}

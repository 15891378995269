@import "../../styles/variables";

#fullcalendar-page {
  margin-top: 1rem;
  margin-bottom: 1rem;
  .absolute {
    position: relative;
    .center {
      position: absolute;
      &--first {
        width: 15rem;
        left: 15rem;
      }
      &--second {
        width: 15rem;
        left: 32rem;
      }
    }
  }

  a:not([href]), a:not([href]):hover {
    color: inherit;
    text-decoration: none;
}

  .fc-now-indicator {
    border: 2px solid $success;
  }
  .fc-now-indicator-arrow {
    display: none;
  }

  .fc-now-indicator.fc-now-indicator-line {
    border-top-width: 0px;
    left: -100vw;
    right: -100vw;
  }

  .fc-axis.fc-time.fc-widget-content {
    color: $primary;
    font-size: large;
  }

  .fc-day-header {
    .column-header {
      font-weight: bold;
      display: flex;
      flex-direction: row;
      flex-grow: 1;
      text-align: center;
    }

    .column-date {
      display: flex;
      flex-direction: row;
      justify-content: space-around;

      .column-date-day {
        color: $primary;
        text-transform: uppercase;
      }
    }
  }
  .fc-today.fc-day {
    background-color: rgba($primary, 0.1) !important;
  }

  .fc-today.fc-day-header {
    background-color: rgba($primary, 0.2);
  }

  .fc-sat,.fc-sun,.day-holiday  {
    background-color: rgba($secondary, 0.3);
  }


  .fc-left h2 {
    text-transform: capitalize;
  }
  .fc-button {
    display: inline-block;
    font-weight: 400;
    color: #4a4a4a;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 2rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  .fc-button-primary {
    color: $primary;
    //  background-color: $primary;
    border-color: $primary;
    &:hover {
      color: #fff;
      background-color: #3e8a26;
      border-color: #398023;
    }
  }
  .fc-button-active {
    color: #fff;

    background-color: darken($primary, 15);
    border-color: darken($primary, 15);
  }

  .fc-button-group > .fc-button:not(:first-child),
  .fc-button-group > .fc-button-group:not(:first-child) {
    margin-left: -1px;
  }

  .fc-button-group > .fc-button:not(:last-child):not(.dropdown-toggle),
  .fc-button-group > .fc-button-group:not(:last-child) > .fc-button {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .fc-button-group > .fc-button:not(:first-child),
  .fc-button-group > .fc-button-group:not(:first-child) > .fc-button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .fc-button-group:first-child > .fc-button:disabled {
    background-color: darken($primary, 15);
    color: $white;
  }
}

@import "../../styles/variables";
.icon {
  &--testcollisions {
    color: $danger;
  }
  margin:3px
}
.testcollisions__list{
  ul{
    padding-left: 1rem;
    list-style-type: square;
  }
  li{
   font-size: 0.9rem;
   text-align: left;
   padding-bottom: 1rem;
  }
}

@import "../../styles/variables";

.icon-userstatus {
  &--active {
  //  color: $primary;
  }
  &--inactive {
    color: $secondary;
  //  color: $danger;
  }
}

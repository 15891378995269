@import "../../../styles/variables.scss";

//$column-width: 100%; // column min width

.table-row-header {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  padding-top: 1em;
  border-bottom: 1px solid $secondary;
  font-weight: bold;
  width: 100%;

  .column-header {
    text-align: center;
  }
  .column-date-header--today {
    //border-bottom: 3px solid $primary;
    background: linear-gradient(180deg, $white 95%, $primary 5%);
  }

  .column-date {
    display: flex;
    flex-direction: column-reverse;
    .column-date-row-date {
      div:first-child {
        padding-right: 1rem;
      }
    }
    .column-date-row-cars {
      font-size: 0.9rem;
      .badge{
        cursor: default;
      }
    }
    .column-date-day {
      color: $primary;
      text-transform: uppercase;
    }
  }
}

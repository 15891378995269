@import "../../../../styles/variables.scss";

#car-info-header {
  .lvrow {
    padding: 5px 0;
    &__label {
      font-size: 0.9rem;
      font-weight: normal;
      text-align: left;
      &::after {
        content: "";
      }
    }
  }
}

@import "../../../styles/variables.scss";

.table-row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  border-bottom: 3px solid $secondary;
  width: 100%;

    page-break-inside: avoid;
    white-space: nowrap;

  &:hover {
    background-color: $table-hover-bg;
  }

  &.row-to-unload {
    background-color: $last-day-background-color;
    &:hover {
      background-color: $last-day-background-color-hover;
    }
  }

  &.row-emission-car {
    background-color: $emission-car-background-color;
    &:hover {
      background-color: $emission-car-background-color-hover;
    }
  }

  &.row-analytics-car {
    background-color: rgba($analysis-background-color, 0.3);
    &:hover {
      background-color: rgba($analysis-background-color, 0.4);
    }
  }

  &.selected-car {
    background-color: rgba($selected-background-color, 0.3);
    &:hover {
      background-color: rgba($selected-background-color, 0.4);
    }
  }

   &.row-to-plan {
    background-color: $to-plan-background-color;
    &:hover {
      background-color: $to-plan-background-color-hover;
    }
  }

  .column {
    padding: 10px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    &-vin {
      button.btn.btn-link {
        padding-top: 0;
      }
    }
  }

  .column-group-items {
    justify-content: flex-start;
  }

  &.new {
    animation: highlight 10s ease;
  }
}

@keyframes highlight {
  0% {
    background: $white;
  }
  50% {
    background: #c3e6cb;
  }
  100% {
    background: $white;
  }
}

:last-child.table-row {
  border-bottom: none;
}

.plancar-status-icons {
  display: flex;
  flex-direction: row;
  &--center {
    justify-content: center;
  }
  &--left {
    justify-content: left;
  }
}

// Typography
//
// Font, line-height, and color for body text, headings, and more.

@font-face {
    font-family: "SKODA Next";
    src: url("../assets/fonts/skoda/SKODANext-BlackItalic.eot");
    src: local("SKODA Next Black Italic"), local("SKODANext-BlackItalic"),
      url("../assets/fonts/skoda/SKODANext-BlackItalic.eot?#iefix")
        format("embedded-opentype"),
      url("../assets/fonts/skoda/SKODANext-BlackItalic.woff") format("woff"),
      url("../assets/fonts/skoda/SKODANext-BlackItalic.ttf") format("truetype");
    font-weight: 900;
    font-style: italic;
  }
  
  @font-face {
    font-family: "SKODA Next";
    src: url("../assets/fonts/skoda/SKODANext-BoldItalic.eot");
    src: local("SKODA Next Bold Italic"), local("SKODANext-BoldItalic"),
      url("../assets/fonts/skoda/SKODANext-BoldItalic.eot?#iefix")
        format("embedded-opentype"),
      url("../assets/fonts/skoda/SKODANext-BoldItalic.woff") format("woff"),
      url("../assets/fonts/skoda/SKODANext-BoldItalic.ttf") format("truetype");
    font-weight: bold;
    font-style: italic;
  }
  
  @font-face {
    font-family: "SKODA Next";
    src: url("../assets/fonts/skoda/SKODANext-Italic.eot");
    src: local("SKODA Next Italic"), local("SKODANext-Italic"),
      url("../assets/fonts/skoda/SKODANext-Italic.eot?#iefix")
        format("embedded-opentype"),
      url("../assets/fonts/skoda/SKODANext-Italic.woff") format("woff"),
      url("../assets/fonts/skoda/SKODANext-Italic.ttf") format("truetype");
    font-weight: normal;
    font-style: italic;
  }
  
  @font-face {
    font-family: "SKODA Next";
    src: url("../assets/fonts/skoda/SKODANext-Light.eot");
    src: local("SKODA Next Light"), local("SKODANext-Light"),
      url("../assets/fonts/skoda/SKODANext-Light.eot?#iefix")
        format("embedded-opentype"),
      url("../assets/fonts/skoda/SKODANext-Light.woff") format("woff"),
      url("../assets/fonts/skoda/SKODANext-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
  }
  
  @font-face {
    font-family: "SKODA Next";
    src: url("../assets/fonts/skoda/SKODANext-Bold.eot");
    src: local("SKODA Next Bold"), local("SKODANext-Bold"),
      url("../assets/fonts/skoda/SKODANext-Bold.eot?#iefix")
        format("embedded-opentype"),
      url("../assets/fonts/skoda/SKODANext-Bold.woff") format("woff"),
      url("../assets/fonts/skoda/SKODANext-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
  }
  
  @font-face {
    font-family: "SKODA Next";
    src: url("../assets/fonts/skoda/SKODANext-LightItalic.eot");
    src: local("SKODA Next Light Italic"), local("SKODANext-LightItalic"),
      url("../assets/fonts/skoda/SKODANext-LightItalic.eot?#iefix")
        format("embedded-opentype"),
      url("../assets/fonts/skoda/SKODANext-LightItalic.woff") format("woff"),
      url("../assets/fonts/skoda/SKODANext-LightItalic.ttf") format("truetype");
    font-weight: 300;
    font-style: italic;
  }
  
  @font-face {
    font-family: "SKODA Next";
    src: url("../assets/fonts/skoda/SKODANext-Regular.eot");
    src: local("SKODA Next"), local("SKODANext-Regular"),
      url("../assets/fonts/skoda/SKODANext-Regular.eot?#iefix")
        format("embedded-opentype"),
      url("../assets/fonts/skoda/SKODANext-Regular.woff") format("woff"),
      url("../assets/fonts/skoda/SKODANext-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
  }
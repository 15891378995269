@import "../../../styles/variables";

.downshift {
    position: relative;

  .downshift-dropdown {
    position: absolute;
    z-index: 2;
    margin: 0 auto;
    width: 100%;
    // margin-right: 2rem;
    border: 1px solid whitesmoke;
    border-bottom: none;
    border-radius: 5px;
    box-shadow:  0 0.3rem 0.8rem rgba($black, 0.3);
  }

  .dropdown-item {
    padding: 0.5rem;
    cursor: pointer;
    border-bottom: 1px solid whitesmoke;
    font-size: 0.8rem;
    text-align: left;
  }

}

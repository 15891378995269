@import "../../../styles/variables";

#settings__testdefinitionstab {
  .selectform {
    padding-top: 1rem;
    padding-bottom: 1rem;
    .row {
      padding-bottom: 1rem;
    }
  }

  td {
    width: 5rem;
    &.center{
      text-align: center; 
    }
  }
  th {
    width: 20rem;
  }
  .header__row {
    text-align: center;
    .header__cell {
      background-color:#7cd465 ;
      padding: 4px;
      border: 3px solid $white;
     
      &--title {
        background-color: $primary;
        color: $light;
      }
    }
  }
  .body__row {
    &:hover {
      background-color: rgba($primary, 0.3);
    }
    .cell {
      display: block;
      &__hours {
        font-size: 0.8rem;
      }
      &__characteristics {
        font-size: 0.6rem;
      }

      &__edit-btn {
        display: none;
        font-size: 0.8rem;
        padding: 0;
      }
    }

    .body__cell {
      border: 3px solid $white;
      padding: 4px;
      text-align: center;
      // &:hover {
      //   background-color: rgba($secondary, 0.3);
      //   // .cell__edit-btn {
      //   //   display: block;
      //   // }
      // }
      &--header {
        background-color: rgba($secondary, 0.6);
        border: 3px solid $white;
        font-weight: bold;

        padding: 8px;
        font-size: 0.8rem;
      }
      &--active {
        background-color: rgba($secondary, 0.1);
        &:hover {
          background-color: rgba($secondary, 0);
        }
      }
      &--inactive {
        background-color: rgba($secondary, 0.4);
        &:hover {
          background-color: rgba($secondary, 0.1);
        }
        &::after {
          content: "-";
        }
      }
    }
  }
}

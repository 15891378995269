@import "../../../styles/variables.scss";

.schedule-container {
  padding: 1rem;
  padding-right: 1px;
  padding-bottom: 1px;
  padding-top: 0px;
  width: 100%;
  .column-date {
    &--today {
      background-color: rgba($secondary, 0.3);
    }
  }
  animation: fadeIn ease 1s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.schedule-body {
  height: 62vh; //calc(100vh - 16rem - 3px - 1rem - 13px);
  overflow-y: auto;
}

@media print {
  .schedule-body {
    height: 100%;
  }
  .schedule-header {
    padding-right: 0px;
    width: 100%;
  }
  .schedule-header {
    width: 100%;
  }
  .table-row-header {
    max-width: 1555px;
    min-width: 1555px;
  }
  .scheduleitem a,
  a:link,
  a:visited,
  a:hover,
  a:active {
    text-decoration: none !important;
  }
  .car-color-icon {
    border: 1px solid black;
  }
  .column-date-row-cars .badge {
    border: 0px solid transparent;
  }
  .column.column-group-items.column-date.column-date--today{
    background-color: transparent;
  }
  .column.column-header.column-date.column-date-header--today{
    border-bottom: 2px solid $primary;
  }
}

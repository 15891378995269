@import "../../styles/variables";
#settings {
  .settings-pane {
    min-height: 70vh;
    padding: 1rem;
  }

  // .settings-sidemenu {
  // }

  .nav-link {
    border-radius: 0;
    &:hover {
      background-color: rgba($primary, 0.5);
    }
    &:focus {
      outline: none;
    }
  }
  .nav-item {
    border-bottom: 1px solid snow;
    &:focus {
      outline: none;
    }
  }
  .nav-link.active {
    box-shadow: 0 0.2rem 0.6rem rgba($black, 0.4);

    &:hover {
      background-color: darken($primary, 4);
    }
  }
  .tab-content {
    margin-top: 0;
  }

  .settings-sidemenu-container {
    padding-right: 0;
    padding-left: 0;
    margin-bottom: 2rem;
    background-color: rgba($primary, 0.2);
  }

  .settings-pane-container {
    padding-left: 0;
    // border: 1px solid $secondary;
    margin-bottom: 2rem;
    border-left: none;
    background-color: rgba($secondary, 0.1);
  }

  .settings__parameters {
    background-color: $white;
    padding: 0.5rem 2rem 2rem 2rem;
  }

  .card-header {
    background-color: $white;
    color: $primary;
    font-size: 1.5rem;
    border-bottom-color: $primary;
    border-bottom-width: 2px;
  }

  .card {
    box-shadow: 0 0.2rem 0.4rem rgba($black, 0.4);
    margin-bottom: 1rem;
  }

  tr.active {
    background-color: rgba($primary, 0.2);
  }

  .card-body div.form-group {
    margin-bottom: 0;
  }

  .card-body div.checkbox {
    padding-top: 2px;
    padding-left: 1.5rem;
  }

  tr.show-btn-on-hover {
    td {
      padding-bottom: 1rem;
    }

    .btn.btn-show-on-hover {
      display: none;
    }
    &:hover {
      .btn.btn-show-on-hover {
        display: inline-flex;
      }
    }
    td.td-fixed-width {
      padding-bottom: 2px;
      width: 7rem;
    }
  }

  .div.div-space-between {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
 
  .car-color-preview{
    width:2rem;
    height:1rem;
    border:1px solid #808080;
    margin-top: 5px;
  }
}
div.editpopover__footer {
  padding-bottom: 0.5rem;
  button.editpopover__button.btn{
    padding-left: 2rem;
    padding-right: 2rem;
    &:nth-child(n+1){
      margin-left: 1rem;
    }
  }
 
}
div.buttongroup-filter{
  padding-right: 10px;
  margin-bottom: 0;
}

@import "../../../styles/variables.scss";
$btn-font-weight: bold;
#popover-legend {
  min-width: 40rem;
}
.legend {
  .list-group {
    &--row {
      display: flex;
      flex-direction: row;
    }
    &--wrapped {
      flex-wrap: wrap;
    }

    &__item {
      padding: 1rem 0.5rem;
      margin: 0.5rem;
      border: 2px solid;
      border-left: 6px solid;
      border-radius: 3px;
      width: 7rem;
      text-align: center;

      @each $color, $value in $colors {
        &--#{$color} {
          background-color: rgba($value, 0.15);
          border-color: rgba($value, 0.8);
          &:hover {
            background-color: rgba($value, 0.3);
          }
        }
      }
    }
  }
  .item__code {
    font-weight: bold;
  }

  .item__description {
    &::before {
      content: " - ";
    }
  }
  .color-square {
    margin-right: 10px;
    margin-bottom: 2px;
    width: 40px;
    height: 30px;
    &--to-unload {
      background-color: $last-day-background-color-hover;
    }
    &--to-plan {
      background-color: $to-plan-background-color-hover;
    }
    &--analytics {
      background-color: rgba($analysis-background-color, 0.4);
    }
  }
}

.footer {
  display: flex;
  flex-direction: row;
  border-top: 3px solid rgba($primary, 0.1);
  margin: 0em 2em;
  justify-content: space-between;

  &-left,
  &-right {
    padding: 1rem;
  }

  &__icongroup {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.8rem;
    font-size: 0.9em;
    width: 5rem;
    cursor: pointer;
    color: rgba($color-primary-typography, 0.7);
    &:hover {
      color: $color-primary-typography;
    }
  }

  &__btn-add {
    box-shadow: 0 0.2rem 0.4rem rgba($black, 0.4);

    &:hover {
      box-shadow: 0 0.4rem 0.8rem rgba($black, 0.5);
    }
  }

  &__rowscount {
    margin: auto;
  }
}

.popover-legend {
  width: 28rem;
  box-shadow: 0 0.3rem 0.8rem rgba($black, 0.5);
}

@import "../../styles/variables";

#carsearch {
  .carsearch {
    // padding: 2rem 5rem;
    padding-top: 0rem;
    padding-bottom: 1rem;
  }

  .carsearch__filters {
    padding-right: 2.5rem;
    padding-top: 2rem;
  }

  .btn-wide{
      width: 10rem;
      margin-right: 1rem;
  }

  .right {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}

@import "../../styles/variables.scss";


#CarTestForm .btn-link {
  &--green {
    color: $primary;
    &:hover{
      color:(darken($color: $primary, $amount: 10))
    }
  }
  &--noverticalpadding{
padding-bottom: 0;
padding-top: 0;
  }
}

.additionalInfoArea {
    border-top: 1px solid grey;
    padding-top: 1rem;
  
    .u-text-right {
      text-align: left !important;
    }
  
    .row {
      margin-left: 0;
    }
  
    .lvrow__label {
      text-align: left;
    }
  
  }
  
@import "../../styles/variables";

.blocking-event-form {
  margin: 1rem 5rem 2rem 0;
  .blocking-event-repeating {
    margin-left: 8rem;
    text-align: center;
    &__type {
      text-transform: lowercase;
    }
    padding-bottom: 1rem;
  }
}

@import "../../../styles/variables.scss";
#MainProtocol {
  .printheader {
    display: none;
  }
  .printfooter {
    display: none;
  }
  .lvrow {
    padding: 5px 0;
    &__label {
      font-size: 0.9rem;
      font-weight: normal;
      text-align: left;
      &::after {
        content: "";
      }
    }
  }
  .table-page-content{
    width:100%;
  }
  .text {
    &--header {
      text-transform: uppercase;
      font-size: larger;
    }
    &--text {
      text-transform: none;
    }
    &--expert-group {
      text-transform: uppercase;
    }
  }
  .pageheader {
    position: sticky;
    top:0px;
    padding-top: 10px;
    background-color: white;
    z-index: 2;
    border-bottom: 1px solid grey;
  }

  @media print {
    @page {
      size: A4 landscape;
    }
    .page-header-space {
      height: 60px;
    }
    .page-footer-space {
      height: 20px;
    }
    thead {
      display: table-header-group;
    }
    tfoot {
      display: table-footer-group;
    }
    .printheader {
      font-size: 0.8em;
      width: 94.5%;
      position: fixed;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      &__right {
        text-align: right;
      }
      &--image {
        display: flex;
        margin: 10px;
        height: 80px;
        width: 262px;
      }
      img {
        max-width: 100%;
      }
    }
    .printfooter {
      font-size: 1em;
      position: fixed;
      bottom: 0;
      width: 100%;
      display: flex;
      border-top:1px solid $secondary;
      &__header {
        font-size: 0.6em;
      }
      td {
        padding: 5px;
        //   border-right:1px solid $secondary;
        //   border-bottom: 1px solid $secondary;
        //   &:last-child{
        //     border-right:none;
        //   }
      }
    }
    .main-protocol-results,
    .main-protocol-footer {
      th {
        border: 1px solid $secondary;
      }
      td {
        border: 1px solid $secondary;
      }
    }
  }
}

@import "../../../styles/variables.scss";

.scheduleitem {
  border: 1px solid;
  border-left: 4px solid;
  border-radius: 0.3em;
  border-bottom-right-radius: 0.9em;
  padding-left: 5px;
  padding-right: 5px;
  margin: 0px 1px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  cursor: pointer;
  font-size: smaller;
  box-shadow: 0 0.1rem 0.4rem rgba(0, 0, 0, 0.2);
  transition: all ease-in 0.1s;
  &__title {
  //  font-size: 1rem;
  }

  &:hover {
    box-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.4);
    transform: translateY(-2px);
    z-index: 10;
  }

  @each $color, $value in $colors {
    &--#{$color} {
      background-color: mix($value, white, 30%);
      border-color: mix($value, white, 80%);
      &:hover {
        background-color: mix($value, white, 50%);
      }
      &.scheduleitem--conflict {
        border: 2px $danger groove;
        // background: repeating-linear-gradient(
        //   135deg,
        //   mix($value, white, 20%),
        //   mix($value, white, 20%) 15px,
        //   mix($value, white, 50%) 15px,
        //   mix($value, white, 50%) 30px
        // );
      }

      &.scheduleitem--late {
        // border: 2px $danger groove;
        background: repeating-linear-gradient(
          135deg,
          mix($value, white, 20%),
          mix($value, white, 20%) 15px,
          mix($value, white, 50%) 15px,
          mix($value, white, 50%) 30px
        );
      }
    }
  }

  .statusicon {
    margin-right: 4px;
    size: 0.7em;

    &--danger {
      color: $danger;
    }

    &--success {
      color: $success;
    }
  }
}

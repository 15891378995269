@import "../../../styles/variables";

#carlist {
  .right {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  .carfilter {
    margin-top: -3.8rem;
  }
  td {
    vertical-align: middle;
  }
  tr.row-to-unload {
    background-color: $last-day-background-color;
    &:hover {
      background-color: $last-day-background-color-hover;
    }
    &.selected {
      background-color: mix(rgba($secondary, 0.4),  $last-day-background-color, 40%);
      &:hover {
        background-color: mix(rgba($secondary, 0.3),$last-day-background-color-hover, 50%) !important;
      }
    }
  }
  tr.transported-car{
    color: $secondary;
    &:hover {
    // background-color: grey;
    }
    &.selected {
      color: mix($secondary,$black, 80%) !important;
      &:hover {
        color: mix($secondary,$black, 70%) !important;
      }
    }
  }
  .icon-priority{
    margin-bottom: -5px;
  }
  tr .form-group {
    margin-bottom: 0rem;
     .form-check {
       vertical-align: middle;
       padding-top: 0rem;
     }
   }
}

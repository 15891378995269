@import "../../../styles/variables";

.colorpicker {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  &__option {
    border-radius: 50%;

    width: 30px;
    height: 30px;
    margin: 4px;
    transition: all 0.2s ease-in-out;
    border: 10px solid;

    @each $color, $value in $colors {
      &--#{$color}{
        background-color: $value;
        border-color: $value;
      }
    }    

    &--selected {
      box-shadow: 0 0.4rem 0.6rem rgba($black, 0.4);
      transform: scale(1.1);
      background-color: $white;
    }

    &--break {
      flex-basis: 100%;
      height: 0;
    }
    
    &:hover:not(.colorpicker__option--disabled) {
      cursor: pointer;
      box-shadow: 0 0.2rem 0.4rem rgba($black, 0.4);
      transform: scale(1.15);
    }
  }
}

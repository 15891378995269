@import "../../styles/variables";

.filter-container {
  position: relative;
  &__buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .user-filters {
    border-right: 2px solid rgba( $secondary, 0.5);
    .btn-dropdownmenu {
      margin: 0;
      padding: 0;
    }
    .nochevron {
      &::after {
        display: none;
      }
    }

   .user-filters__row{
    display: flex;
    justify-content: space-between;
    padding: 0.6rem 0.4rem;
   }

   &__list{
    overflow-y: auto;
    height: 16rem;
   }
  }

  .btn {
    margin: 0.25rem;
    min-width: 2.5rem;
  }
  &__filters {
    border-radius: 5px;
    box-shadow: 0 0.1rem 0.5rem rgba($black, 0.1);
    padding: 1rem;

    background-color: $light;
    margin: 5px;
    .select__menu {
      z-index: 2;
    }
    &__button {
      width: 10rem;
    }
  }
}

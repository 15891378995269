@import "../../styles/variables.scss";
@import "../../styles/variables";
.emission-date{
    color:$primary;
    font-weight: bold;
}

.emission-planned-dates
{
  color: $info;
}
@import "../../../styles/variables";

#cardetail-history{
    .history-item{
        list-style-type:none;
        border-left: 6px solid $primary;
        padding: 0.5rem;
        margin: 0.25rem;

        &:hover{
            background-color: $table-hover-bg;
        }

        &--test{
            border-left-width: 6px;
        }

        &--car{
            border-left-width: 4px;
        }
    }
}
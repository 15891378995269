@import "../../../styles/variables";
// .css-1azwzxf-control {
//  // border: 1px solid #ced4da;
//   background-color: #e9ecef;
//   color: #495057;
// }


// .css-1rhbuit-multiValue{
//     border: 1px solid #ced4da;
// }

.css-1ty9hxy-multiValue{
  background-color: rgba($info,0.1) !important;
  &:hover{
    background-color: rgba($info,0.2) !important;
  }
}
.multiselectinput-invalid-feedback{
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #e62336;
}